<template>
  <div>
    <page-header-layout>
      <el-card>
        <div style='width: 100%;text-align: center'>
          <el-link style='font-weight: bold;font-size: 28px'
                   href='https://open.dingtalk.com/document/orgapp/queries-the-complete-information-of-a-department-user'
                   target='_blank'>钉钉成员
          </el-link>
        </div>
      </el-card>
      <el-row :gutter='15' style='margin-top: 5px'>
        <el-col :span='4'>
          <el-card>
            <DeptPanel @handleSelect='selectDept'></DeptPanel>
          </el-card>
        </el-col>
        <el-col :span='20'>
          <el-card>
            <div class='table-search'>
              <el-form :model='searchCondition' inline size='mini'>
                <el-form-item label='部门：'>
                  <span>{{ searchCondition.dept_name || '不限' }}</span>
                </el-form-item>
                <el-form-item label='入职时间：'>
                  <el-date-picker
                    v-model='searchCondition.date_range'
                    type='daterange'
                    align='right'
                    unlink-panels
                    range-separator='至'
                    start-placeholder='开始日期'
                    end-placeholder='结束日期'
                    value-format='yyyy-MM-dd'
                    :picker-options='pickerOptions'>
                  </el-date-picker>
                </el-form-item>
                <el-form-item label='姓名：'>
                  <el-input placeholder='用户姓名' v-model='searchCondition.name' clearable></el-input>
                </el-form-item>
                <el-form-item label='在职情况：'>
                  <el-select v-model='searchCondition.is_leave' style='width: 100px' clearable>
                    <el-option label='在职' value='N'></el-option>
                    <el-option label='离职' value='Y'></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-row :gutter='15' style='margin-bottom: 8px'>
              <el-col :span='12'>
                <el-button type='text' icon='el-icon-user' @click='getRealCount' title='查看钉钉成员人数-包含未激活钉钉的人员数量'>
                  钉钉成员：{{ real_count }} 人，系统已同步，在职:{{ num_job }} 人，离职:{{ num_leave }} 人
                </el-button>
                <el-button v-if='num_no_account>0' type='warning' @click='registerAccountUsers' title='注册系统账号：已同步但未注册为系统账号的人员'>
                  注册（{{ num_no_account }}）
                </el-button>
              </el-col>
              <el-col :span='12' style='text-align: right'>
                <el-tooltip placement='bottom' effect='light' content='钉钉信息已同步但未注册为系统账号的人员'>
                  <el-button type='warning' @click='showNoAccountUsers' title='钉钉信息已同步但未注册为系统账号的人员'>
                    未注册人员（{{ num_no_account }}）
                  </el-button>
                </el-tooltip>
              </el-col>
            </el-row>
            <div class='default-table'>
              <!--    渲染表格-->
              <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                       :loading='loadingStatus' @sort-change='changeTableSort' border>
                <el-table-column label='操作' width='100' align='center'>
                  <template slot-scope='{row}'>
                    <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>
                  </template>
                </el-table-column>
              </m-table>
              <!--    渲染分页-->
              <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                            @pagination='getList' />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </page-header-layout>

    <!-- 操作日志详细 -->
    <el-dialog title='详情' :visible.sync='open' width='700px' append-to-body>
      <el-form ref='form' :model='form' size='mini'>
        <el-form-item label='员工姓名：'>
          <span>{{form.name}}</span>
        </el-form-item>
        <el-form-item label='open_id：'>
          <span>{{form.open_id}}</span>
        </el-form-item>
        <el-form-item label='union_id：'>
          <span>{{form.union_id}}</span>
        </el-form-item>
        <el-form-item label='userid：'>
          <span>{{form.userid}}</span>
        </el-form-item>
        <el-form-item label='在职状态：'>
          <span>{{form.is_leave_alias}}</span>
        </el-form-item>
        <el-form-item label='同步时间：'>
          <span>{{form.created_at}}</span>
        </el-form-item>
        <el-form-item label='更新时间：'>
          <span>{{form.updated_at}}</span>
        </el-form-item>
        <el-form-item>
          <el-button type='danger' @click='deleteDingTalkInfo(form)'>删除</el-button>
        </el-form-item>
<!--        <el-row>-->
<!--          <el-col :span='24'>-->
<!--            <el-form-item label=''>-->
<!--              <el-input :autosize='{ minRows: 15, maxRows: 40}' type='textarea'-->
<!--                        :value='jsonData'></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='open = false'>关 闭</el-button>
      </div>
    </el-dialog>
    <!--    数据统计报告-->
    <el-dialog title='数据统计' :visible.sync='openStatistics' fullscreen append-to-body>
      <div style='display: flex;flex-direction: row'>
        <div style='flex: 4'>
          <h3>历史报告</h3>
          <div>
            <el-table :data='reportList' border>
              <el-table-column label='报告标题' align='center'>
                <template slot-scope='{row}'>{{ row.title }}({{ row.created_at }})</template>
              </el-table-column>

            </el-table>
          </div>
        </div>
        <div style='flex: 18;padding: 10px'>
          <div>
            <el-button>生成报告</el-button>
          </div>
          <div>
            <el-table :data='reportDataList' border>
              <el-table-column label='报告明细' align='left' header-align='center'>
                <template slot-scope='{row}'>
                  {{ row.dept_name }}:在职{{ row.on_num }},入职：{{ row.enter_num }}，离职：{{ row.leave_num }}
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
      <!--      <div slot='footer' class='dialog-footer'>-->
      <!--        <el-button @click='openStatistics = false'>关 闭</el-button>-->
      <!--      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import DeptPanel from '@/components/dept/DeptPanel'

export default {
  name: 'DingUserInfoList',
  components: { DeptPanel, PageHeaderLayout },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      form: {},
      loadingStatus: false,
      orderSort: { id: 'desc' },
      searchCondition: { dept_name: null, dpt_id: null, name: null, is_leave: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '用户姓名',
          value: 'name',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '邮箱',
          value: 'email',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '入职时间',
          value: 'hired_at',
          field: 'hiredDate',
          width: 120,
          sortable: 'custom'
        },

        {
          title: '职位',
          value: 'position',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '手机号',
          value: 'mobile',
          width: 100,
          sortable: 'custom'
        }, {
          title: '在职情况',
          value: 'is_leave_alias',
          field: 'is_leave',
          width: 90,
          sortable: 'custom'
        },
        {
          title: '办公地址',
          value: 'workPlace',
          width: 120
        },
        {
          title: '备注',
          value: 'remark',
          width: 300
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      leaveStatusMap: { 'Y': '离职', 'N': '在职' },
      currRow: {},
      dialogVisible: false,
      real_count: 0,
      num_job: 0,
      num_leave: 0,
      num_no_account: 0,//无账号数
      openStatistics: false,
      reportList: [
        { title: '2023年电商中心人员数据统计', created_at: '2023/10/24' }
      ],//报告列表
      reportDataList: [
        { dept_name: '电商一部', enter_num: 10, leave_num: 10, on_num: 100 },
        { dept_name: '电商一部', enter_num: 10, leave_num: 10, on_num: 100 }

      ]//报告明细
    }
  },
  computed: {
    jsonData() {
      if (this.form)
        return JSON.stringify(this.form, null, 4)
      else
        return JSON.stringify({})
      // return this.formData.notify_msg || {}
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    selectDept(data) {
      this.searchCondition.dpt_id = data.dpt_id
      this.searchCondition.dept_name = data.name
      this.handleQuery()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.name) {
        condition.name = this.searchCondition.name
      }
      if (this.searchCondition.dpt_id) {
        condition.dpt_id = this.searchCondition.dpt_id
      }
      if (this.searchCondition.is_leave) {
        condition.is_leave = this.searchCondition.is_leave
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getDingUserInfoList(searchCondition)
      list.map((item) => {
        item['is_leave_alias'] = this.leaveStatusMap[item.is_leave]
      })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })

    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
    async getRealCount() {
      let { info, state } = await this.$api.getDingUserRealCount()
      this.$nextTick(() => {
        this.real_count = info.count || 0
        this.num_job = state.num_job || 0
        this.num_leave = state.num_leave || 0
        this.$notify.success(`钉钉用户数量：${this.real_count}`)
      })


    },
    showStatistics() {
      this.openStatistics = true
    },
    async showNoAccountUsers() {
      let { list } = await this.$api.getNoAccountUsers()
      this.dataList = list || []
      this.total = list.length
    },
    //注册
    async registerAccountUsers() {
      this.$confirm('此操作将注册已同步但未注册系统账号的钉钉成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let { registerFail, registerSuccess } = await this.$api.registerAccountByDingUserInfo()
        // console.log(registerFail)
        // console.log(registerSuccess)
        this.$message({
          type: 'success',
          message: `操作成功：成功-${registerSuccess.length} 失败-${registerFail.length}`
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })

    },
    async showNoAccountUserCount() {
      let { count } = await this.$api.getNoAccountUserCount()
      this.num_no_account = count
    },
    deleteDingTalkInfo(row){
      this.$confirm(`此操作将删除的钉钉成员[${row.name}]的信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
         await this.$api.deleteDingTalkUserInfo(row.id)
        // console.log(registerFail)
        // console.log(registerSuccess)
        this.$message({
          type: 'success',
          message: `操作成功：`
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }

  },
  mounted() {
    this.getList()
    this.showNoAccountUserCount()
    // this.getRealCount()
  }
}
</script>

<style scoped>

</style>
